<template>
  <div class="n-container">
    <div>
      <el-col :span="24">
        <div class="block">
          <el-calendar v-model="recordTime" :first-day-of-week="7">
            <template slot="dateCell" slot-scope="{ date, data }">
              <div slot="reference" class="histyle" @click="getDateAtte(data)">
                <div class="calendar-day">
                  <div slot="reference">
                    <p class="day">
                      {{ data.day.split("-").slice(2).join("-") }}
                    </p>
               
                    <p v-if="selected.indexOf((data.day).trim())>-1" class="sign-tips">已签</p>
                    <p class="sign-tips waitSign"  v-if="selected.indexOf((data.day).trim()) == -1&&Date.parse(data.day)<=currentTime">未签</p>
                  </div>
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
      </el-col>
    </div>
  </div>
</template>
   
  <script>

export default {
  data() {
    return {
      recordTime:'',
      currentTime:this.getCurrentTime()
    };
  },
  props: ["selected"],
  created() {},
  watch: {
    'recordTime'(newVal,oldVal){
        this.$emit('upadteTime',newVal)
    }
  },
  mounted(){
    // console.log(this.selected)
  },
  methods: {
    getCurrentTime(){
      var year = new Date().getFullYear()
      var mouth = new Date().getMonth() + 1
      var day = new Date().getDate()
      var currentTime = year + '-'+mouth +'-'+day
      var timer = Date.parse(currentTime)
      return timer
    },
    getDateAtte(val){
      // console.log(this.currentTime,Date.parse(val.day))
      if(this.currentTime ==  Date.parse(val.day)){
        if(this.selected.indexOf(val.day) == -1){
          
        }
      }
    },
  },
};
</script>
   
  <style lang="scss"  scoped>
.prev .sign-tips,
.next .sign-tips {
  color: #bfbfbf;
}
.sign-tips {
  font-size: 10px;
  color: #f50537;
}
.calendar-day p {
  margin: 5px 0;
}
.waitSign{
  color: #999999;
}
.n-container {
  padding: 0px;

  width: 400px;
  margin-bottom: 10px;
  overflow: hidden;
  padding-bottom: 16px;
  margin: 0;
  background-color: #f2f2f2;
  border: none;
}
.title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.calendar-day .day {
  font-size: 16px;
  font-weight: 600;
}
::v-deep  .el-calendar__body {
  background-color: #f5f5f5;
  padding: 0;
}
::v-deep .el-calendar__header {
  border: none;
  background-color: #f2f2f2;
}
::v-deep .el-calendar-table tr:first-child td {
  border: none;
}
::v-deep .el-calendar-table td {
  border: none;
}
::v-deep .el-calendar-table .el-calendar-day {
  height: 62px !important;
}
::v-deep .el-calendar-table td.is-today .calendar-day,
::v-deep .el-calendar-table td.is-today .calendar-day p {
  color: #ffffff;
  background-color: #000000;
}
::v-deep .el-calendar-table td.is-today .calendar-day {
  padding: 1px 0;
  border-radius: 4px;
}
.tpadding {
  padding-top: 12px;
}
.is-selected .calendar-day,
.is-selected .calendar-day p {
  color: #ffffff;
  background-color: #000000;
  padding: 1px 0;
  border-radius: 4px;
}
.histyle {
  height: 35px;
}
.el-calendar-table .el-calendar-day {
  height: 30px;
}
.is_waring {
  color: rgb(236, 134, 17);
}
.is_right {
  color: rgb(145, 176, 235);
}
::v-deep .el-calendar-table td.is-selected {
  background-color: transparent;
}
</style>