import request from '@/utils/request'
import qs from 'qs';


export function eventLog (params) {
  return request({
    url:'/aitp-master/common/saveStatisticsClickEvent',
    mtf: 'st',
    data:params
  })
}

export function pvLog (params) {
  return request({
    url:'/aitp-master/common/saveStatisticsPv',
    mtf: 'st',
    data:params
  })
}
