
export default {
    routes: [
        {
            path: "/groupBuild",
            name: "groupBuild",
            meta:{title:'团队建设'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/groupBuild/index.vue"),
          },
          {
            path: "/groupDetail",
            name: "groupDetail",
            meta:{title:'团队管理'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/groupBuild/groupDetail.vue"),
          },
    
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };