
export default {
    routes: [
        {
            path: "/scriptPlatform",
            name: "scriptPlatform",
            meta:{title:'话术共创'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/scriptPlatform/index.vue"),
          },
          {
            path: "/labelPanel",
            name: "labelPanel",
            meta:{title:'标签'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/scriptPlatform/labelPanel.vue"),
          },
          {
            path: "/scriptDetail",
            name: "scriptDetail",
            meta:{title:'话术详情'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/scriptPlatform/detail.vue"),
          },
          {
            path: "/listPanel",
            name: "listPanel",
            meta:{title:'我的'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/scriptPlatform/listPanel.vue"),
          },
          {
            path: "/history",
            name: "history",
            meta:{title:'往期话题'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/scriptPlatform/history.vue"),
          },
          {
            path: "/history",
            name: "history",
            meta:{title:'往期话题'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/scriptPlatform/history.vue"),
          },
          
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
  