
export default {
	routes: [
		{
			path: "/satisfactionSurvey",
			name: "satisfactionSurvey",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'问卷调研'},
			component: () =>
				import(/* webpackChunkName: "satisfactionSurvey" */ "@/views/satisfactionSurvey/index"),
		},
		{
			path: "/satisfactionSurveyHome",
			name: "satisfactionSurveyHome",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'问卷调研'},
			component: () =>
				import(/* webpackChunkName: "satisfactionSurvey" */ "@/views/satisfactionSurvey/start"),
		},
		{
			path: "/satisfactionSurveyForPcHome",
			name: "satisfactionSurveyForPcHome",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'问卷调研'},
			component: () =>
				import(/* webpackChunkName: "satisfactionSurvey" */ "@/views/satisfactionSurveyForCourse/index"),
		},
		{
			path: "/satisfactionSurveyHomeEnd",
			name: "satisfactionSurveyHomeEnd",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'问卷调研'},
			component: () =>
				import(/* webpackChunkName: "satisfactionSurvey" */ "@/views/satisfactionSurvey/end"),
		},
		,
		{
			path: "/satisfactionSurveyBoard",
			name: "satisfactionSurveyBoard",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'培训课程满意度看板'},
			component: () =>
				import(/* webpackChunkName: "satisfactionSurvey" */ "@/views/satisfactionSurvey/board"),
		},
	],
	// 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
	// path 的值与 iframeRoutePath 相等
	iframes: []
};
