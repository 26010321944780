
// import i18n from '@/lang';
const envConfig = require('./env.' + process.env.VUE_APP_NODE_ENV)


const config = {
  // 接口相关
  prefix: '/aitp-master', // ajax 请求前缀
  baseURL:envConfig.baseURL,

  //   站点配置
  systemName: "奥迪互动培训平台",
  lang: 'zh', // 语言
  size: 'default', // 尺寸
  maxCacheNum: 10, // 路由组件最大缓存数量
  
  showSettings: true,
  tagsView: true,
  fixedHeader: true,
  sidebarLogo: true,

  // element ui 尺寸的映射
  toElementSize: {
    large: 'medium',
    default: 'small',
    small: 'mini'
  },
  charts: {
    // 文字大小
    textSize: 12,
    // 文字颜色
    textColor: 'rgba(0, 0, 0, 0.65)',
    // 悬浮框背景颜色
    bgColor: 'rgba(255, 255, 255, 0.85)',
    // 柱状图/折线图鼠标经过的背景颜色
    barBgColor: 'rgba(0, 0, 0, 0.05)',
    // 盒子外发光效果
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.35)',
    // 工具提示文字颜色
    tipTextColor: 'rgba(0, 0, 0, 0.65)',
    // 坐标轴颜色
    lineColor: 'rgba(0, 0, 0, 0.35)'
  },
  // vDesign 组件库的全局配置
  vDesign: {
    TopFilter_showLabelErrorColor: true,
    FormPanel_showLabelErrorColor: true
  }
};

export default config;
