
export default {
    routes: [
        {
            path: "/knowledge",
            name: "knowledge",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'知识管理'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/knowledge"),
          },
          {
            path: "/clt",
            name: "clt",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'投放培训'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/clt"),
          },
          {
            path: "/star",
            name: "star",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'奥迪之星'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/star"),
          },
          {
            path: "/knowledgeIndex",
            name: "knowledgeIndex",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'知识管理'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/index"),
          },
          {
            path: "/knowledgeSearch",
            name: "knowledgeSearch",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'知识管理'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/search"),
          },
          {
            path: "/knowledgeHistory",
            name: "knowledgeHistory",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'知识管理'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/history"),
          },
          {
            path: "/knowledgeMystery",
            name: "knowledgeMystery",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'奥迪之星'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/mystery"),
          },
          {
            path: "/mysteryDetails",
            name: "mysteryDetails",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'奥迪之星'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/mysteryDetails"),
          },
          {
            path: "/knowledgeSubject",
            name: "knowledgeSubject",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'主题征集区'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/subject"),
          },
          {
            path: "/subjectDetail",
            name: "subjectDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'主题征集区详情'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/subjectDetail"),
          },
          {
            path: "/caseDetails",
            name: "caseDetails",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'优秀案例'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/caseDetails"),
          },
          {
            path: "/integralRanking",
            name: "integralRanking",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'积分排行'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/integralRanking"),
          },
          {
            path: "/excellentCase",
            name: "excellentCase",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'优秀案例'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/excellentCase"),
          },
          {
            path: "/caseList",
            name: "caseList",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'优秀案例'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/caseList"),
          },
          {
            path: "/knowledgeHotList",
            name: "knowledgeHotList",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'热榜推荐'},
            component: () =>
              import(/* webpackChunkName: "knowledge" */ "@/views/knowledge/hotList"),
          },
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
  