import moment from 'moment';
// moment.locale('en', {
//   longDateFormat: {
//     l: "YYYY-MM-DD",
//     L: "YYYY-MM-DD HH:mm"
//   }
// });

 export default {
     // 获取当天的开始结束时间
    getCurrentDays:function() {
        const startDate = moment().startOf("days").format("YYYY-MM-DD");
        const endDate = moment().endOf("days").format("YYYY-MM-DD");
        return [startDate, endDate];
    },
    // 获取当天的开始结束时间
    getCurrentDaysTime:function() {
        const startDate = moment().startOf("days").format("YYYY-MM-DD  HH:mm:ss");
        const endDate = moment().endOf("days").format("YYYY-MM-DD  HH:mm:ss");
        return [startDate, endDate];
    },
    // 获取当天的开始结束时间
    getCurrentDaysTimeE:function() {
        const startDate = moment().startOf("days").format("YYYY-MM-DD  HH:mm:ss");
        const endDate = moment().endOf("days").format("YYYY-MM-DD  HH:mm:ss");
        return [new Date(startDate), new Date(endDate)];
    },

    // 获取当周的开始结束时间，周日至周一
    getCurrentWeekdays:function() {
        const startDate = moment().startOf("weeks").format("YYYY-MM-DD");
        const endDate = moment().endOf("weeks").format("YYYY-MM-DD");
        return [startDate, endDate];
    },

    // 获取当月的开始结束时间
    getCurrentMonth:function() {
        const startDate = moment().startOf("month").format("YYYY-MM-DD");
        const endDate = moment().endOf("month").format("YYYY-MM-DD");
        return [startDate, endDate];
    },

    // 获取上周的开始结束时间
    getLastWeekdays:function() {
        const startDate = moment().week(moment().week() - 1).startOf("weeks").format("YYYY-MM-DD");
        const endDate = moment().week(moment().week() - 1).endOf("weeks").format("YYYY-MM-DD");
        return [startDate, endDate];
    },

    // 获取上月的开始结束时间
    getLastMonth:function() {
        const startDate = moment().month(moment().month() - 1).startOf("month").format("YYYY-MM-DD");
        const endDate = moment().month(moment().month() - 1).endOf("month").format("YYYY-MM-DD");
        return [startDate, endDate];
    },
    // 获取下周的开始结束时间
    getNextWeekdays:function() {
        const startDate = moment().week(moment().week() + 1).startOf("weeks").format("YYYY-MM-DD");
        const endDate = moment().week(moment().week() + 1).endOf("weeks").format("YYYY-MM-DD");
        return [startDate, endDate];
    },
    // 获取下月的开始结束时间
    getNextMonth:function() {
        const startDate = moment().month(moment().month() + 1).startOf("month").format("YYYY-MM-DD");
        const endDate = moment().month(moment().month() + 1).endOf("month").format("YYYY-MM-DD");
        return [startDate, endDate];
    },
     // 获取n天前的开始结束时间
    getBeforeNdaysNow:function(n) {
        // 减法 moment.subtract(number, String)
        const startDate = moment().subtract(n, 'days').format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        return [startDate, endDate];
    },

    // 获取n天前的开始结束时间
    getBefore3days:function() {
        // 减法 moment.subtract(number, String)
        const startDate = moment().subtract(3, 'days').format("YYYY-MM-DD");
        const endDate = moment().subtract(3, 'days').format("YYYY-MM-DD");
        return [startDate, endDate];
    },
    // 获取n天后的开始结束时间
    getAfter3days:function() {
        // 减法 moment.add(number, String)
        const startDate = moment().add(3, 'days').format("YYYY-MM-DD");
        const endDate = moment().add(3, 'days').format("YYYY-MM-DD");
        return [startDate, endDate];
    },
    // 获取2099年
    get2099Date:function() {
        const startDate = moment('2099-12-31 23:59:59');
        return startDate.format('YYYY-MM-DD HH:mm:ss');
    },
    // 获取当前时间
    getNowDateTime:function() {
      return moment().format('YYYY-MM-DD HH:mm:ss');
    },
    // 获取当前日期
    getNowDate:function() {
      return moment().format('YYYY-MM-DD');
    },
    // 获取昨天时间
    getYestodayDateTime:function() {
      return moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    },
    // 比较两个时间
    getDiffDate:function(firstDate, secondDate) {
      return moment(Number(secondDate)).diff(moment(Number(firstDate)), 'seconds')
    },
 }
