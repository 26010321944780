import { MessageBox, Notification, Message } from 'element-ui';
import store from '@/store';
// import i18n from '@/lang';

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}
/**
 * @description 延迟方法，异步函数
 * @param {number} delay 延迟的时间，单位 毫秒
 * @returns
 */
export const sleep = async delay => {
  return new Promise(resolve => setTimeout(resolve, delay));
};
/**
* @description 需要确认的操作提示
* @param {string} msg 提示的文本
* @param {string} type 提示类型
* @returns
*/
export const confirmAction = async (msg = '', type = 'warning') => {
  return MessageBox.confirm(msg, '', { confirmButtonText: '确认', cancelButtonText: '取消', type, dangerouslyUseHTMLString: true });
};
/**
**
 * @description Notification 通知提示
 * @param {string} msg 提示的文本
 * @param {string} type 提示类型
 * @param {number} delay 延迟的时间，单位 秒，如果值是 0，为手动关闭模式
 * @returns
 */
export const notifyAction = async (msg = '', type = 'success', delay = 4.5) => {
  //   if (config.onlyOneMessage && store.state.app.isNotifyMark) return;
  //   store.dispatch('app/createNotifyState', true);
  await sleep(0);
  Notification({ title: "", message: msg, type, duration: delay * 1000, dangerouslyUseHTMLString: true });
  await sleep(delay * 1000);
  //   store.dispatch('app/createNotifyState', false);
};

/**
 * @description Message 消息提示
 * @param {string} msg 提示的文本
 * @param {string} type 提示类型
 * @param {number} delay 延迟的时间，单位 秒
 * @returns
 */
export const messageAction = async (msg = '', type = 'info', delay = 4) => {
  if (config.onlyOneMessage && store.state.app.isNotifyMark) return;
  //   store.dispatch('app/createNotifyState', true);
  await sleep(0);
  Message({ message: msg, showClose: true, type, duration: delay * 1000 });
  await sleep(delay * 1000);
  //   store.dispatch('app/createNotifyState', false);
};

/**
 * @description 字符串转 moment 对象
 * @param {string|array} value 入参
 * @param {string} valueFormat 日期类型
 * @returns moment 对象
 */
export const stringToMoment = (value, valueFormat) => {
  if (Array.isArray(value)) {
    return value.map(val => (typeof val === 'string' && val ? moment(val, valueFormat) : val || null));
  } else {
    return typeof value === 'string' && value ? moment(value, valueFormat) : value || null;
  }
};

/**
 * @description moment 对象转成日期格式字符串
 * @param {string|array} value 入参
 * @param {string} valueFormat 日期类型
 * @returns 转换后的日期格式字符串
 */
export const momentToString = (value, valueFormat) => {
  if (Array.isArray(value)) {
    return value.map(val => (moment.isMoment(val) ? val.format(valueFormat) : val));
  } else {
    return moment.isMoment(value) ? value.format(valueFormat) : value;
  }
};

/**
 * @description 判断表单控件的值是否为空
 * @param {string} val 表单项的值
 * @returns {boolean} 表单是否为空
 */
export const isFormEmpty = val => {
  return typeof val === 'undefined' || val === '' || val === null;
};

/**
 * @description 生成 uuid
 * @param {string} key uuid 的前缀标识
 * @returns {boolean} 生成的 uuid 字符串
 */
export const createUidKey = (string = '') => {
  const chars = '0123456789abcdef';
  let uuid = '';
  const randomIndexes = crypto.getRandomValues(new Uint32Array(8));
  uuid += chars[randomIndexes[0] & 0xf];
  uuid += chars[(randomIndexes[0] >> 4) & 0xf];
  uuid += chars[(randomIndexes[0] >> 8) & 0xf];
  uuid += chars[(randomIndexes[0] >> 12) & 0xf];
  uuid += '-';
  uuid += chars[randomIndexes[1] & 0xf];
  uuid += chars[(randomIndexes[1] >> 4) & 0xf];
  uuid += chars[(randomIndexes[1] >> 8) & 0xf];
  uuid += chars[(randomIndexes[1] >> 12) & 0xf];
  uuid += '-';
  uuid += '4';
  uuid += chars[(randomIndexes[2] & 0x3) | 0x8];
  uuid += chars[(randomIndexes[2] >> 4) & 0xf];
  uuid += chars[(randomIndexes[2] >> 8) & 0xf];
  uuid += '-';
  uuid += chars[(randomIndexes[3] & 0xf)];
  uuid += chars[(randomIndexes[3] >> 4) & 0xf];
  uuid += chars[(randomIndexes[3] >> 8) & 0xf];
  uuid += chars[(randomIndexes[3] >> 12) & 0xf];
  uuid += '-';
  for (let i = 4; i < 8; i++) {
    uuid += chars[(randomIndexes[i] & 0xf)];
    uuid += chars[(randomIndexes[i] >> 4) & 0xf];
    uuid += chars[(randomIndexes[i] >> 8) & 0xf];
    uuid += chars[(randomIndexes[i] >> 12) & 0xf];
  }
  return string + uuid;
};


/**
 * @description 动态加载 js 文件
 * @param {string} url js 文件地址
 * @param {func} callback 回调函数
 * @returns
 */
export const loadScript = (url, callback) => {
  let head = document.getElementsByTagName('head')[0];
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.onload = script.onreadystatechange = function () {
    if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
      callback && callback();
      script.onload = script.onreadystatechange = null;
      if (head && script.parentNode) {
        head.removeChild(script);
      }
    }
  };
  head.appendChild(script);
};

export function downloadExcel(blob, filename) {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, decodeURI(filename));
  } else {
    const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
    let a = document.createElement('a');
    a.href = downloadUrl;
    a.download = decodeURI(filename);
    a.click();
    a = null;
  }
}
