import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import config from '@/config';
import routes from './routes';

Vue.use(VueRouter);

export const constantRouterMap = [
  {
    path: "/index",
    name: "index",
    component: index,
    meta: { title: '首页' }

  },
  {
    path: "/login",
    name: "login",
    meta: { title: '登录' },
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/login/index.vue"),
  },
  {
    path: "/persionCenter",
    name: "persionCenter",
    meta: { title: '个人中心' },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/persionCenter/index.vue"),
  },
  {
    path: "/about",
    name: "About",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/404.vue"),
  }
 
];

export const asyncRouterMap = [
    ...routes.map(x => x.routes).flat(),
    {path: '*', redirect: '/index', hidden: true}
]

const router = new VueRouter({
  mode: "history",
  base:  config.basePath,
  routes:[...constantRouterMap,...asyncRouterMap],
});

export default router;
