<template>
  <div id="app">
    <Header
      v-if="
        currentRoute !== '/login' &&
        currentRoute !== '/' &&
        currentRoute !== '/liveDetail' &&
        currentRoute !== '/satisfactionSurvey' &&
        currentRoute !== '/satisfactionSurveyHome' &&
        currentRoute !== '/satisfactionSurveyHomeEnd' &&
        currentRoute !== '/audiStarGame' &&
        currentRoute !== '/satisfactionSurveyForPcHome'
      "
    ></Header>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
    >
      <div style="min-height: calc(100vh - 400px)">
        <router-view :key="$route.fullPath" />
      </div>
    </transition>
    <Footer
      v-if="
        currentRoute !== '/login' &&
        currentRoute !== '/' &&
        currentRoute !== '/liveDetail' &&
        currentRoute !== '/satisfactionSurvey' &&
        currentRoute !== '/satisfactionSurveyHome' &&
        currentRoute !== '/satisfactionSurveyHomeEnd' &&
        currentRoute !== '/satisfactionSurveyForPcHome'
      "
    ></Footer>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="640px"
      :show-close="false"
    >
      <div class="form">
        <el-form
          :model="formInline"
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="100px"
        >
          <el-form-item label="旧密码:" prop="oldPassword">
            <el-input
              type="password"
              v-model="formInline.oldPassword"
              placeholder="请输入旧密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="newPassword">
            <el-input
              v-model="formInline.newPassword"
              type="password"
              maxlength="25"
              minlength="12"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <span class="pass-word-tip"
            >规则为至少12位，数字＋字母(大写或小写)＋特殊字符组合，非连续性或重复字符、数字</span
          >
          <el-form-item label="确认新密码:" prop="newPasswordAgain">
            <el-input
              v-model="formInline.newPasswordAgain"
              type="password"
              maxlength="25"
              minlength="8"
              placeholder="请确认输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 100%; height: 38px"
              @click="onSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { Header, Footer } from "@/components/Layout";
import { updatePassword } from "@/api/user";
import { notifyAction, confirmAction } from "@/utils";
import { selectAudiStarTestSwith } from "@/api/audiStar";
export default {
  name: "App",
  components: {
    Header: Header,
    Footer: Footer,
  },
  data() {
    var regex =
      /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){12,25}$/;
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (!regex.test(value)) {
        callback(
          new Error(
            "密码必须为至少12位数字＋字母(大写或小写)＋特殊字符组合，非连续性或重复字符、数字"
          )
        );
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (!regex.test(value)) {
        callback(
          new Error(
            "密码必须为至少12位数字＋字母(大写或小写)＋特殊字符组合，非连续性或重复字符、数字"
          )
        );
      } else if (value !== this.formInline.newPassword) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };
    return {
      show: false,
      dialogVisible1: false,
      dialogVisible: false,
      formInline: {
        newPassword: "",
        oldPassword: "",
        newPasswordAgain: "",
      },
      rules: {
        newPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPasswordAgain: [
          { required: true, validator: validatePass1, trigger: "blur" },
        ],
      },
      audiStarTestSwith: Boolean(
        Number(localStorage.getItem("audiStarTestSwith"))
      ),
    };
  },

  computed: {
    currentRoute() {
      this.$nextTick(function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
      if (
        this.$route.path.indexOf("/specialTest/examinationCopy") > -1 &&
        this.audiStarTestSwith
      ) {
        return "/audiStarGame";
      } else if (
        this.$route.path.indexOf("/specialTest/testPaperDetail") > -1 &&
        this.audiStarTestSwith
      ) {
        return "/audiStarGame";
      } else {
        return this.$route.path;
      }
    },
    userInfo: function () {
      if (
        this.$store.getters.userInfo.role == 6 &&
        this.$store.getters.userInfo.collegeType == 1
      ) {
        this.$router.push({ path: "/groupBuild" });
      } else if (
        this.$store.getters.userInfo.role == 6 &&
        this.$store.getters.userInfo.collegeType == 2
      ) {
        this.$router.push({ path: "/persionCenter?activeIndex=10" });
      }
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    "userInfo.isUpdatePassword": {
      handler(newVal, oldVal) {
        if (newVal == 0) {
          this.dialogVisible = true;
        }
      },
      deep: true,
      immediate: true,
    },
    // "currentRoute": {
    //   handler(newVal, oldVal) {
    //     if (newVal == '/audiStarGame') {
    //       this.getAudiStarTestSwith()
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  mounted() {

  },
  methods: {

    openNew(url) {
      if (
        url == "https://pc-aitp.faw-vw.com" &&
        new Date().getTime() > new Date("2024-06-27 20:00:00").getTime()
      ) {
        window.open(url);
      }
      if(url != "https://pc-aitp.faw-vw.com" ){
         window.open(url);
      }
    },
    // getAudiStarTestSwith() {
    //   selectAudiStarTestSwith({}).then(res => {
    //     if (res.code === 200) {
    //       this.audiStarTestSwith = Boolean(res.data)
    //     }
    //   })
    // },
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        // console.log(valid);
        if (valid) {
          updatePassword({
            oldPassword: this.formInline.oldPassword,
            newPassword: this.formInline.newPassword,
          }).then((res) => {
            if (res.code == 200) {
              this.dialogVisible = false;
              notifyAction("修改成功", "success");
              this.$store
                .dispatch("FedLogOut", this.params)
                .then(() => {
                  this.$router.push({ path: "/login" });
                })
                .catch(() => {});
            } else {
              notifyAction("修改失败", "success");
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">

@font-face {
  font-family: "DFPKingGothicGB-Regular";
  src: url("./static/font/DFPKingGothicGB-Regular.woff2") format("woff2"),
    url("./static/font/DFPKingGothicGB-Regular.woff") format("woff");
}
@font-face {
  font-family: "DFPKingGothicGB-Medium";
  src: url("./static/font/DFPKingGothicGB-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "AudiTypeV01-Bold";
  src: url("./static/font/AudiTypeV01-Bold.otf") format("truetype");
}
@font-face {
  font-family: "DFPKingGothicGB-Semibold";
  src: url("./static/font/DFPKingGothicGB-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "AudiType-ExtendedBold";
  src: url("./static/font/AudiType-ExtendedBold_4.03.ttf") format("truetype");
}
#app {
  font-family: "DFPKingGothicGB-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  word-break: break-all;
  // height: 1000px;
  // background-color: red;
}
.form {
  width: 100%;
  margin: 0 auto;
}
.el-scrollbar {
  .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
<style scoped>
.pass-word-tip {
  font-size: 12px;
  position: relative;
  top: -3px;
  color: dimgray;
  left: 33px;
}
</style>
