
export default {
    routes: [
      {
        path: "/speechSharingIndex",
        name: "speechSharingIndex",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta:{title:'经销商沟通指导'},
        component: () =>
          import(/* webpackChunkName: "verbalTrick" */ "@/views/verbalTrick/speechSharing/index.vue"),
        },
        {
          path: "/speechSharingIndex",
          name: "speechSharingIndex",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'话术共享'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/verbalTrick/speechSharing/index.vue"),
        },
        {
          path: "/speechSharingList",
          name: "speechSharingList",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'话术共享',pvTitle:'话术共享列表'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/verbalTrick/speechSharing/list.vue"),
          hidden: true
        },
        {
          path: "/speechSharingDetails",
          name: "speechSharingDetails",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          meta:{title:'话术共享',pvTitle:'话术共享学习'},
          component: () =>
            import(/* webpackChunkName: "verbalTrick" */ "@/views/verbalTrick/speechSharing/details.vue"),
          hidden: true
        },
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
