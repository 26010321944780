import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import qs from 'qs';
import { notifyAction } from '@/utils';
import { getLoginTime } from '@/utils/storage'
import DateUtil from '@/utils/DateUtil';
import config from '@/config';

// 请求异常提示信息
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  412: '客户端请求信息的先决条件错误。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
};

// 创建axios实例

const service = axios.create({
  baseURL: config.baseURL, // api的base_url
  timeout: 3000000, // 请求超时时间
  withCredentials: false, // 跨域请求时是否需要使用凭证
  // paramsSerializer: params => {
  //   // 序列化 GET 请求参数 -> a: [1, 2] => a=1&a=2
  //   return qs.stringify(params, { arrayFormat: 'repeat' });
  // }
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
})

// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改

  }
  return config
}, error => {
  const { response = {} } = error;
  const errortext = codeMessage[response.status] || response.statusText || "网络请求异常";
  if (errortext) {
    notifyAction(errortext, 'error', 10);
  }
  return Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
    * code为非200是抛错 可结合自己业务进行修改
    */
    const res = response.data
    if (res.code !== 200) {


      // 401:未登录;
      if (res.code === 401) {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('FedLogOut').then(() => {

            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      } else if (res.code === 403) {
        store.dispatch('FedLogOut').then(() => {

          location.reload()// 为了重新实例化vue-router对象 避免bug
        })
      } else {
        Message({
          message: res.message,
          type: 'error',
          duration: 3 * 1000
        })
      }


      return Promise.reject('error')
    } else {

      // let loginTime = getLoginTime() || null
      // let currentTime = String(new Date().getTime())
      // let time = DateUtil.getDiffDate(loginTime, currentTime)

      // if (loginTime && (time / 60 > 180)) { // 超过3小时刷新token
      //   if (response && response.data && !response.data.refreshToken) { store.dispatch('RESETTOKEN') }
      // }

      return response.data
    }
  },
  error => {
    // console.log('err' + error)// for debug

    const { response = {} } = error;
    const errortext = codeMessage[response.status] || response.statusText || "网络请求异常";
    if (errortext) {
      notifyAction(errortext, 'error', 10);
    }
    return Promise.reject(error);
  }
)



const newRequest = config => {
  let newConfig = {...config};
  if (config.mtf === 'st') {
    newConfig[String.fromCharCode(109,101,116,104,111,100)] = String.fromCharCode(112,111,115,116); 
  }
  if (config.mtf === 'et') {
    newConfig[String.fromCharCode(109,101,116,104,111,100)] = String.fromCharCode(103,101,116);
  }
  delete newConfig['mtf']
  return service(newConfig);

};
export default newRequest
