
export default {
	routes: [
		{
			path: "/audiStar",
			name: "audiStar",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'奥迪之星'},
			component: () =>
				import(/* webpackChunkName: "audiStar" */ "@/views/audiStar/index"),
		},
		{
			path: "/starVideo",
			name: "starVideo",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'奥迪之星'},
			component: () =>
				import(/* webpackChunkName: "audiStar" */ "@/views/audiStar/video.vue"),
		},
		{
			path: "/photoAlbum",
			name: "photoAlbum",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'奥迪之星',pvTitle:'精彩回顾详情'},
			component: () =>
				import(/* webpackChunkName: "audiStar" */ "@/views/audiStar/photoAlbum.vue"),
		},
		{
			path: "/audiStarGame",
			name: "audiStarGame",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			meta:{title:'奥迪之星',pvTitle:'赛事公告'},
			component: () =>
				import(/* webpackChunkName: "audiStar" */ "@/views/audiStar/audiStarGame/index.vue"),
		},

	],
	// 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
	// path 的值与 iframeRoutePath 相等
	iframes: []
};
