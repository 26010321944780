// ...
/**
 * 百度统计-页面中的用户行为
 * index.html模板文件中需要先预定于 var _hmt = _hmt || [];
 */
export function behavior(action, label, value) {
    if (value === undefined) {
        window._hmt.push(['_trackEvent', 'category', action, label])
    } else {
        window._hmt.push(['_trackEvent', 'category', action, label, value])
    }
}