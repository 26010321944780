const files = require.context('./modules', true, /\.route\.js$/);

let configRouters = [];

files.keys().forEach(key => {
  configRouters = configRouters.concat(files(key).default);
  return configRouters;
  // console.log(configRouters)
});

export default configRouters;
