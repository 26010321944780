
export default {
    routes: [
        {
            path: "/trajectory",
            name: "trajectory",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'学习轨迹'},
            component: () =>
              import(/* webpackChunkName: "training" */ "@/views/trajectory/index"),
          },    
  
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
  