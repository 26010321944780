<template>
  <header>
    <div id="header" class="header">
      <div class="nav-global">
        <div class="container header-container">
          <img
            class="pointer"
            src="../../assets/common/header/logo.png"
            @click="goIndex()"
            alt=""
          />
          <img
            class="audi-zh"
            @click="goIndex()"
            src="../../assets/common/header/audi-zh.png"
            alt=""
          />
        </div>
        <div class="nav-list">
          <ul class="nav-ul">
            <li
              v-for="(item, index) in menuList"
              @click="changeSelectMenu(index)"
              :key="index"
            >
              <router-link
                active-class="active"
                v-if="item.component.indexOf('http') == -1 && item.hidden == 0"
                class="router"
                :to="item.component"
              >
                {{ item.name }}
              </router-link>
              <a
                v-if="item.component.indexOf('http') > -1 && item.hidden == 0"
                :href="item.component"
                target="_blank"
              >
                {{ item.name }}</a
              >
              <ul class="sub-menu" v-if="item.children.length > 0">
                <li v-for="(v, idx) in item.children" :key="idx">
                  <router-link
                    active-class="active"
                    :to="v.component"
                    v-if="
                      v.component.indexOf('http') == -1 &&
                      v.hidden == 0 &&
                      v.component.indexOf('thirdParty') == -1
                    "
                  >
                    {{ v.name }}
                  </router-link>
                  <!-- <a
                    v-if="
                      v.component.indexOf('thirdParty') > -1 && v.hidden == 0
                    "
                    @click="jumpThirdParty(v.name)"
                    href="javascript:;"
                  >
                    {{ v.name }}</a
                  > -->
                </li>
              </ul>
            </li>
          </ul>
          <div class="user-action">
            <!-- <div class="search">
              <img src="../../assets/common/header/icon-search.png" alt="" />
              <input type="text" placeholder="查找课程" />
            </div> -->
            <div class="user-action-copy" v-if="!isCollege">
              <div
                class="message-wraper"
                v-if="showNotice"
                @mouseleave="mouseleave"
              >
                <div class="ms-header">
                  <div>
                    <img
                      src="../../assets/common/icon/icon-delete.png"
                      alt=""
                    />
                    <span @click="readMessage">全部标记已读</span>
                  </div>
                  <div>
                    <span @click="goMessage">查看所有通知</span>
                    <img src="../../assets/common/icon/icon-right.png" alt="" />
                  </div>
                </div>

                <div
                  class="ms-item"
                  v-for="item in messageList"
                  :key="item.id"
                  @click="goDetail(item)"
                >
                  <div class="item-header">
                    <span class="tips-type">{{ item.typeName }}</span>
                    <span class="tips-time">{{ item.addtime }}</span>
                  </div>
                  <div class="tips-content">
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <div style="position: relative; margin-right: 40px">
                <el-input
                  placeholder="检索"
                  class="search-input"
                  v-model="searchValue"
                  @change="changeValue"
                >
                </el-input>
                <i
                  @click="changeValue('')"
                  class="search-icon el-icon-search"
                ></i>
              </div>

              <img
                @click="downLoadVisible = !downLoadVisible"
                src="../../assets/common/header/ew.png"
                alt=""
              />
              <span
                @click="downLoadVisible = !downLoadVisible"
                style="margin-right: 8px"
                class="pointer"
              ></span>
              <img
                class="qrcode"
                v-if="downLoadVisible"
                src="../../assets/index/qrcode.png"
                alt=""
              />

              <el-badge
                v-if="unread"
                :value="unread"
                class="item"
                @click="goMessage"
              >
                <img
                  @click="goMessage"
                  v-if="activeIndex !== 'message'"
                  src="../../assets/common/header/icon-email.png"
                  alt=""
                />
                <img
                  @click="goMessage"
                  v-else
                  src="../../assets/common/header/icon-email-on.png"
                  alt=""
                />
              </el-badge>

              <img
                @click="goMessage"
                @mouseenter="mouseenter"
                v-if="activeIndex !== 'message' && !unread"
                src="../../assets/common/header/msg.png"
                style="margin-left: 20px"
                alt=""
              />
              <img
                @click="goMessage"
                @mouseenter="mouseenter"
                v-if="activeIndex == 'message' && !unread"
                src="../../assets/common/header/icon-email-on.png"
                alt=""
              />
              <span
                @mouseenter="mouseenter"
                class="pointer"
                @click="goMessage"
                :style="
                  activeIndex == 'message'
                    ? 'font-weight:600'
                    : 'font-weight:400'
                "
              ></span>
            </div>
            <el-popover
              trigger="click"
              popper-class="popover"
              placement="top"
              width="100"
              class="popover"
              v-model="visible"
            >
              <p
                v-if="userInfo.isCollegeAuth == 1"
                @click="jdAction"
                style="width: 100px; padding-left: 46px; cursor: pointer"
              >
                人才招聘
              </p>
              <p
                @click="signIn"
                style="width: 100px; padding-left: 46px; cursor: pointer"
              >
                个人中心
              </p>

              <!-- <p
                v-if="userInfo.adminRole"
                @click="signAdmin"
                style="width: 100px; padding-left: 46px; cursor: pointer"
              >
                后台登录
              </p> -->
              <p
                @click="loginOut()"
                style="width: 100px; padding-left: 46px; cursor: pointer"
              >
                退出登录
              </p>
              <div slot="reference">
                <img class="user-photo" :src="userInfo.photo" alt="" />
                <!-- <span>
                  <span class="pointer" style="margin: 0">个人中心</span>
                <img class="arrow-bottom" src="../../assets/common/header/icon-bottom.png" alt="" />
                </span> -->
              </div>
            </el-popover>
            <div
              @click="signAdmin"
              class="gzt"
              v-if="userInfo.isWorkbenchAuth == 1"
            >
              <img
                style="margin-left: 20px; width: 20px"
                src="../../assets/common/header/gzt-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible" width="400px">
      <span>{{ latestQuestions.title }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="notice" @click="checkDetail">查看详情</el-button>
      </span>
    </el-dialog>
  </header>
</template>
<script>
// import Breadcrumb from './Breadcrumb'
import {
  unreadCount,
  portalToAdmin,
  readMessage,
  getUserMail,
  getOldAitpSystemUrl,
} from "@/api/user";
import { TypedChainedSet } from "webpack-chain";
import { parseTime } from "@/utils";
const envConfig = require("@/config/env." + process.env.VUE_APP_NODE_ENV);
export default {
  name: "Header",
  data() {
    return {
      downLoadVisible: false,
      dialogVisible: false,
      showNotice: false,
      activeIndex: "",
      messageCount: 0,
      latestQuestions: "",
      visible: false,
      messageList: [],
      searchValue: "",
    };
  },
  watch: {
    "userInfo.id": {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          // this.initData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // this.initData();
    var that = this;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    that.$store
      .dispatch("GetUnRead", { id: that.userInfo.id })
      .then((res) => {
        if (res.code == 200) {
          if (res.data.latestQuestions) {
            that.dialogVisible = true;
            that.latestQuestions = res.data.latestQuestions;
          }

          that.messageCount = res.data.unreadCount;
        }
      })
      .catch(() => {
        that.loading = false;
        // this.getCaptcha();
      });
  },
  computed: {
    userInfo: function () {
      return this.$store.getters.userInfo;
    },
    unread: function () {
      return this.$store.getters.unread;
    },
    menuList: function () {
      return this.$store.getters.menu;
    },
    isCollege: function () {
      if (this.$store.getters.userInfo.role == 6) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    jdAction() {
      this.$router.push({
        path: "/groupBuild",
      });
    },
    initData() {
      var that = this;

      // this.$store
      //   .dispatch("GetUnRead", { id: this.userInfo.id })
      //   .then(() => {
      //     // this.loading = false;
      //     // this.$router.push({ path: "/index" });
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //     // this.getCaptcha();
      //   });

      // getUserMail({ pageSize: 4, pageNum: 1 }).then((res) => {

      //   if (res.code == 200) {
      //     res.list.map((item) => {
      //       item.addtime = parseTime(item.addtime, "{y}-{m}-{d} {h}:{i}:{s}");
      //     });

      //     that.messageList = res.list;
      //   }
      // });
    },

    goDetail(item) {
      this.showNotice = false;
      if (item.type == 1) {
        if (item.resourceId) {
          this.$router.push({
            path: "/courseDetail",
            query: { id: item.resourceId },
          });
        } else {
          notifyAction("课程不存在或已下架", "warning");
        }
      } else if (item.type == 2) {
        if (item.resourceId) {
          this.$router.push({
            path: "/questionDetail",
            query: { id: item.resourceId },
          });
        } else {
          notifyAction("社区ID内容不存在", "warning");
        }
      } else if (item.type == 5) {
        if (item.resourceId) {
          window.open(
            window.location.origin +
              "/satisfactionSurveyHome?id=" +
              item.resourceId
          );
          // this.$router.push({
          //   path: "/satisfactionSurveyHome",
          //   query: { id: item.resourceId },
          // });
        } else {
          notifyAction("在线调研不存在", "warning");
        }
      }
    },
    changeValue(val) {
      var data = val || this.searchValue;
      if (data !== "") {
        this.$store.dispatch("getSearchValue", data).then(() => {
          // 生成可访问的路由表
          this.$router.push({
            path: "/search",
            query: { type: val },
          });
        });
      }
    },
    handleClose() {},
    mouseenter() {
      this.showNotice = !this.showNotice;
    },
    mouseleave() {
      this.showNotice = false;
    },
    readMessage() {
      var that = this;
      readMessage({}).then((res) => {
        if (res.code == 200) {
          that.showNotice = false;
          unreadCount({ id: this.userInfo.id }).then((res) => {
            if (res.code == 200) {
              debugger;
              if (res.data.latestQuestions) {
                that.dialogVisible = true;
                that.latestQuestions = res.data.latestQuestions;
              }
              that.$store
                .dispatch("GetUnRead", { id: that.userInfo.id })
                .then(() => {
                  // this.loading = false;
                  // this.$router.push({ path: "/index" });
                })
                .catch(() => {
                  that.loading = false;
                  // this.getCaptcha();
                });
              that.messageCount = res.data.unreadCount;
            }
          });
        } else {
        }
      });
    },
    checkDetail(item) {
      var that = this;
      readMessage({ id: item.id }).then((res) => {
        this.dialogVisible = false;
        this.$router.push({
          path: "/questionDetail",
          query: {
            id: this.latestQuestions.resourceId,
          },
        });
      });
    },
    changeSelectMenu(val) {
      this.activeIndex = val;
    },
    signIn() {
      this.activeIndex = "sign";
      this.$router.push({
        path: "/persionCenter",
        query: {
          activeIndex: 0,
        },
      });
      this.visible = false;
    },
    signAdmin() {
      portalToAdmin({}).then((res) => {
        if (res.code == 200) {
          var token = res.data.token;
          var url =
            envConfig.dmpUrl +
            "/#/oauth" +
            "?token=" +
            token +
            "&refresh=" +
            res.data.refreshToken;
          window.open(url);
        }
      });
      this.visible = false;
    },
    goMessage() {
      this.showNotice = false;
      this.activeIndex = "message";
      this.$router.push({
        path: "/persionCenter",
        query: {
          activeIndex: 4,
        },
      });
    },
    goIndex() {
      if (!isCollege) {
        this.$router.push({
          path: "/index",
        });
      }
    },
    loginOut() {
      this.$store
        .dispatch("LogOut", this.params)
        .then(() => {
          this.$router.push({ path: "/login" });
        })
        .catch(() => {
          // this.loading = false;
          // this.getCaptcha();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.popover {
  // min-width: 140px !important;
  // width: 140px;
  cursor: pointer;
  margin-left: 20px;
}
.arrow-bottom {
  position: relative;
  top: 4px;
}
::v-deep {
  .el-input__inner {
    width: 100%;
    text-align: left;
    border: 0;
    border-bottom: 1px solid #000;
    outline: none;
    border-radius: 0;
    padding-left: 33px;
  }
}
.search-icon {
  position: absolute;
  left: 0px;
  top: 12px;
}

.user-action {
  position: relative;
  .message-wraper {
    width: 400px;
    position: absolute;
    display: flex;
    padding: 20px;
    flex-direction: column;
    background-color: #ffffff;
    top: 41px;
    right: 63px;
    z-index: 1100;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    .ms-header {
      width: 100%;
      display: flex;
      padding-bottom: 20px;
      justify-content: space-between;
      border-bottom: 1px solid #b3b3b3;
    }
    .ms-item {
      flex-direction: column;
      padding: 20px 0;
      width: 100%;
      cursor: pointer;
      .item-header {
        width: 100%;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .tips-type {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
      }
      .tips-time {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-left: 10px;
        color: #999999;
      }
      .tips-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 10px;
        color: #000000;
        text-align: left;
        width: 100%;
        white-space: pre-line;
      }
    }
  }
}

.ms-header span {
  cursor: pointer;
}
.ms-header span:hover {
}
.nav-list > ul {
  display: flex;
}

.notice {
  width: 100%;
  border: none;
  color: #f50537;
}
.gzt {
  font-size: 14px;
  cursor: pointer;
}
::v-deep .el-dialog__body {
  border-bottom: 1px solid #f0f0f0;
}
.nav-list > ul > li .active {
  font-weight: 600;
}
.nav-list > ul > li {
}
.qrcode {
  width: 100px !important;
  height: 100px;
  position: absolute;
  top: 36px;
  left: 1px;
  z-index: 9999;
}
.sub-menu {
  //display: flex;
  position: absolute;
  z-index: 999999999;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1920px;
  padding: 0;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  top: 150px;
  display: flex;
  background-color: #fff;
  width: 100%;
  opacity: 0;
  padding-left: 200px;
  height: 70px;
  pointer-events: none;
  box-sizing: border-box;
  transition: all 0.3s;
}
.nav-list li .sub-menu li {
  background-color: #ffffff;
}
.gzt {
}
.nav-list li .sub-menu li a {
  margin: 0;
  width: 100%;
  text-align: center;
  color: #000000;
  font-size: 14px;
}
.nav-list li .sub-menu li a:hover,
.nav-list li .sub-menu li .active {
  color: #000;
  font-weight: bold;
}
.sub-menu li {
  &:hover {
    border: none !important;
  }
}
.user-photo {
  width: 24px !important;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}
::v-deep {
  .search-input .el-input__inner {
    width: 200px;
  }
}

.header {
  max-width: 1920px;
  margin: auto;
  background-color: #fff;
  box-shadow: 0px 0px 8px 1px rgba(185, 185, 185, 0.21);
  .header-container {
    padding: 25px 82px;
    align-items: center;
    .pointer {
      cursor: pointer;
      width: 60px;
    }
    .audi-zh {
      width: 70px;
    }
  }
}
.active a {
  font-weight: 600;
}
.container {
  display: flex;
  justify-content: space-between;
}

.nav-list {
  display: flex;
  padding: 0 82px 0;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  .nav-ul {
    margin: 0;
    height: 100%;
    li {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.nav-list li {
  list-style: none;
  margin-right: 30px;
  cursor: pointer;
  &:nth-last-of-type(1) {
    margin-right: 0;
  }
  &:hover {
    .sub-menu {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.nav-list li {
  &:hover {
    border-bottom: 2px solid #000;
  }
}
.nav-list li a {
  text-decoration: none;
  font-size: 14px;
  color: #000000;
}

.user-action,
.user-action div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.user-action-copy {
  justify-content: space-around;
  position: relative;
}
.user-action-copy img {
  cursor: pointer;
  width: 20px;
}
.search {
  position: relative;
}
.search img {
  position: absolute;
  top: 10px;
  left: 15px;
  cursor: pointer;
}
.search input {
  width: 80px;
  height: 36px;
  background-color: #f2f2f2;
  border-radius: 36px;
  border: 1px solid #f2f2f2;
  padding-left: 40px;
}
</style>
