
export default {
    routes: [
        {
            path: "/oldTraining",
            name: "training",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'投放培训'},
            component: () =>
              import(/* webpackChunkName: "training" */ "@/views/training/index"),
          },
          {
            path: "/training",
            name: "training",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'投放培训'},
            component: () =>
              import(/* webpackChunkName: "training" */ "@/views/training/courseSubject"),
          },
          {
            path: "/trainingDetail",
            name: "trainingDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'投放培训'},
            component: () =>
              import(/* webpackChunkName: "training" */ "@/views/training/subjectDetail"),
          },
          {
            path: "/imageList",
            name: "imageList",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'投放培训'},
            component: () =>
              import(/* webpackChunkName: "training" */ "@/views/training/imageList"),
          },
          {
            path: "/wonderfulReview",
            name: "wonderfulReview",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'投放培训'},
            component: () =>
              import(/* webpackChunkName: "training" */ "@/views/training/wonderfulReview/index"),
          },




    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
