var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(
      _vm.currentRoute !== '/login' &&
      _vm.currentRoute !== '/' &&
      _vm.currentRoute !== '/liveDetail' &&
      _vm.currentRoute !== '/satisfactionSurvey' &&
      _vm.currentRoute !== '/satisfactionSurveyHome' &&
      _vm.currentRoute !== '/satisfactionSurveyHomeEnd' &&
      _vm.currentRoute !== '/audiStarGame' &&
      _vm.currentRoute !== '/satisfactionSurveyForPcHome'
    )?_c('Header'):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn"}},[_c('div',{staticStyle:{"min-height":"calc(100vh - 400px)"}},[_c('router-view',{key:_vm.$route.fullPath})],1)]),_vm._v(" "),(
      _vm.currentRoute !== '/login' &&
      _vm.currentRoute !== '/' &&
      _vm.currentRoute !== '/liveDetail' &&
      _vm.currentRoute !== '/satisfactionSurvey' &&
      _vm.currentRoute !== '/satisfactionSurveyHome' &&
      _vm.currentRoute !== '/satisfactionSurveyHomeEnd' &&
      _vm.currentRoute !== '/satisfactionSurveyForPcHome'
    )?_c('Footer'):_vm._e(),_vm._v(" "),_c('el-dialog',{attrs:{"title":"修改密码","visible":_vm.dialogVisible,"close-on-click-modal":false,"close-on-press-escape":false,"width":"640px","show-close":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"form"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formInline,"rules":_vm.rules,"label-position":"left","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"旧密码:","prop":"oldPassword"}},[_c('el-input',{attrs:{"type":"password","placeholder":"请输入旧密码"},model:{value:(_vm.formInline.oldPassword),callback:function ($$v) {_vm.$set(_vm.formInline, "oldPassword", $$v)},expression:"formInline.oldPassword"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"新密码:","prop":"newPassword"}},[_c('el-input',{attrs:{"type":"password","maxlength":"25","minlength":"12","placeholder":"请输入新密码"},model:{value:(_vm.formInline.newPassword),callback:function ($$v) {_vm.$set(_vm.formInline, "newPassword", $$v)},expression:"formInline.newPassword"}})],1),_vm._v(" "),_c('span',{staticClass:"pass-word-tip"},[_vm._v("规则为至少12位，数字＋字母(大写或小写)＋特殊字符组合，非连续性或重复字符、数字")]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"确认新密码:","prop":"newPasswordAgain"}},[_c('el-input',{attrs:{"type":"password","maxlength":"25","minlength":"8","placeholder":"请确认输入新密码"},model:{value:(_vm.formInline.newPasswordAgain),callback:function ($$v) {_vm.$set(_vm.formInline, "newPasswordAgain", $$v)},expression:"formInline.newPasswordAgain"}})],1),_vm._v(" "),_c('el-form-item',[_c('el-button',{staticStyle:{"width":"100%","height":"38px"},attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("提交")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }