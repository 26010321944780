
export default {
    routes: [
        {
            path: "/community",
            name: "community",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'社区互动'},
            component: () =>
              import(/* webpackChunkName: "community" */ "@/views/communityArea"),
          },
        {
            path: "/personalHomepage",
            name: "personalHomepage",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'个人主页'},
            component: () =>
              import(/* webpackChunkName: "community" */ "@/views/communityArea/personalHomepage.vue"),
          },
        {
            path: "/cardDetail",
            name: "cardDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'个人主页'},
            component: () =>
              import(/* webpackChunkName: "community" */ "@/views/communityArea/cardDetail.vue"),
          },
        {
            path: "/oldCommunity",
            name: "oldCommunity",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'社区互动'},
            component: () =>
              import(/* webpackChunkName: "community" */ "@/views/community"),
          },
          {
            path: "/shareDetail",
            name: "shareDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'社区互动',pvTitle:'社区详情'},
            component: () =>
              import(/* webpackChunkName: "community" */ "@/views/community/shareDetail"),
          },
          {
            path: "/questionDetail",
            name: "questionDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'社区互动',pvTitle:'提问详情'},
            component: () =>
              import(/* webpackChunkName: "community" */ "@/views/community/questionDetail"),
          },
          {
            path: "/commentDetail",
            name: "commentDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'社区互动',pvTitle:'评论详情'},
            component: () =>
              import(/* webpackChunkName: "community" */ "@/views/community/commentDetail"),
          },


    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
