<template>
  <div class="footer" id="footer">
    <div class="wraper">
      <div class="logo-box">
        <img src="../../assets/common/footer/d_logo.png" alt="" />
        <p class="audi-text">奥迪互动培训平台</p>
      </div>
      <!-- <div class="footer-left">
        <div>
          <p @click="goUrl('https://audidtms.faw-vw.com/')">DTMS</p>
          <p @click="goUrl('https://www.audi.cn/')">一汽奥迪</p>
        </div>
      </div> -->
      <div class="code-box">
        <div class="box">
          <img src="../../assets/common/footer/code-app.png" alt="" />
          <div class="t">扫码即可下载APP</div>
        </div>
        <div class="box">
          <img src="../../assets/common/footer/code-wx.png" alt="" />
          <div class="t">扫码关注公众号</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="copy-right">
        <span>Copyright 2022.All rights reserved</span>
        <div>
          <span @click="goUrl('https://beian.miit.gov.cn/')"
            >吉ICP备09000793号-5</span
          >

          <img
            style="position: relative; top: 3px"
            src="http://www.beian.gov.cn/file/ghs.png"
            alt=""
          />
          <span
            style="cursor: pointer"
            @click="goUrl('http://www.beian.gov.cn')"
            >吉公网安备22017102000263号</span
          >
        </div>

        <span
          >TEL：0411-62916000 0431-85990175
          <a
            style="color: white; text-decoration: none"
            href="mailto:aitp@runlin.cn"
            >E-MAIL：aitp@runlin.cn</a
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.audi-text {
  font-size: 16px;
  color: #fff;
}
.footer-left p {
  margin-bottom: 6px;
  font-size: 14px;
  color: #000000;
  opacity: 0.6;
  cursor: pointer;
}
.footer-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.footer-left > div {
  width: 100px;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.wraper {
  max-width: 1920px;
  margin: 0 auto;
  padding: 50px 82px 25px 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .logo-box {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    img {
      width: 130px;
    }
  }
  .code-box {
    margin-top: 60px;
    display: flex;
    align-items: center;
    .box {
      margin-left: 30px;
      img {
        width: 68px;
        height: 68px;
        border-radius: 2px;
        object-fit: cover;
      }
      .t {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        margin-top: 12px;
      }
    }
  }
}
.footer {
  width: 100%;
  background-color: #111;
  position: relative;
}
.bottom {
  max-width: 1920px;
  height: 37px;
  background-color: #000;
  padding: 0 82px;
}
.copy-right {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  font-size: 12px;
  color: #ffffff;
}
</style>
