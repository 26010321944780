
export default {
    routes: [
        {
            path: "/trainingPlan",
            name: "trainingPlan",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'培训专区'},
            component: () =>
              import(/* webpackChunkName: "reportPanel.route" */ "@/views/reportPanel/trainingPlan.vue"),
          },
        {
            path: "/trainingPlan",
            name: "trainingPlan",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'培训计划'},
            component: () =>
              import(/* webpackChunkName: "reportPanel.route" */ "@/views/reportPanel/trainingPlan.vue"),
          },
        {
            path: "/trainingUpload",
            name: "trainingUpload",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'上传'},
            component: () =>
              import(/* webpackChunkName: "reportPanel.route" */ "@/views/reportPanel/trainingUpload.vue"),
          },
        {
            path: "/trainingTask",
            name: "trainingTask",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'培训任务'},
            component: () =>
              import(/* webpackChunkName: "reportPanel.route" */ "@/views/reportPanel/trainingTask.vue"),
          },
		  {
		      path: "/trainingOperations",
		      name: "trainingOperations",
		      // route level code-splitting
		      // this generates a separate chunk (about.[hash].js) for this route
		      // which is lazy-loaded when the route is visited.
		      meta:{title:'培训运营'},
		      component: () =>
		        import(/* webpackChunkName: "reportPanel.route" */ "@/views/reportPanel/trainingOperations.vue"),
		    },
		  
		  
		  
         
          {
            path: "/nev",
            name: "nev",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'NEV技能大赛'},
            component: () =>
              import(/* webpackChunkName: "reportPanel.route" */ "@/views/reportPanel/nev.vue"),
          },
          {
            path: "/groupBuild",
            name: "groupBuild",
            meta:{title:'人才招聘'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/groupBuild/index.vue"),
          },
          {
            path: "/groupDetail",
            name: "groupDetail", 
            meta:{title:'团队管理'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/groupBuild/groupDetail.vue"),
          },
          {
            path: "/recruit",
            name: "recruit", 
            meta:{title:'团队招募'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/groupBuild/recruit.vue"),
          },
          {
            path: "/management",
            name: "management", 
            meta:{title:'高校合作管理'},
            component: () =>
            import(/* webpackChunkName: "live" */ "@/views/groupBuild/management.vue"),
          },
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
  