const getters = {

  token: state => state.user.token,

  userInfo: state => state.user.userInfo,

  addRouters: state => state.permission.addRouters,
  routers: state => state.permission.routers,
  menu: state => state.user.menu,
  unread: state => state.user.unread,
  courseType:state => state.user.courseType,
  postCourseType:state => state.user.postCourseType,
  searchValue:state => state.user.searchValue,
  trackData:state => state.user.trackData,
};
export default getters;
