
import request from '@/utils/request'

// 查询发布的赛事介绍列表分页
export function introductionSelectpage(params) {
	return request({
		url: '/aitp-master/portal/introduction/selectpage',
		 mtf: 'et',
		params: params
	})
}

// 查询赛程进展列表分页
export function progressSelectpage(params) {
	return request({
		url: '/aitp-master/portal/progress/selectpage',
		 mtf: 'et',
		params: params
	})
}

// 公告列表
export function selectNewsNotice(params) {
	return request({
		url: '/aitp-master/portal/home/selectNewsNotice',
		 mtf: 'et',
		params: params
	})
}

// 赛事介绍详情
export function introductionDetail(params) {
	return request({
		url: '/aitp-master/portal/introduction/introductiondetail',
		mtf: 'st',
		params: params
	})
}

// 查询竞赛历程
export function selectRaceProcess(params) {
	return request({
		url: '/aitp-master/portal/audiStar/selectRaceProcess',
		 mtf: 'et',
		params: params
	})
}

// 查询分类列表
export function selectReplayTypeList(params) {
	return request({
		url: '/aitp-master/common/selectReplayTypeList',
		 mtf: 'et',
		params: params
	})
}

// 星采展示列表
export function getStarsUserList(params) {
	return request({
		url: '/aitp-master/portal/audiStar/getStarsUserList',
		 mtf: 'et',
		params: params
	})
}

// 星采展示列表
export function getReviewList(params) {
	return request({
		url: '/aitp-master/portal/audiStar/getReviewList',
		 mtf: 'et',
		params: params
	})
}

// 获取精彩回顾详情
export function getReviewById(params) {
	return request({
		url: '/aitp-master/portal/audiStar/getReviewById',
		 mtf: 'et',
		params: params
	})
}

// 星采展示观看完成
export function readStarsUserFinish(params) {
	return request({
		url: '/aitp-master/portal/audiStar/readStarsUserFinish',
		 mtf: 'et',
		params: params
	})
}

// 查询奥迪之星考试开关
export function selectAudiStarTestSwith(params) {
	return request({
		url: '/aitp-master/common/selectAudiStarTestSwith',
		 mtf: 'et',
		params: params
	})
}

