
export default {
  routes: [
    {
      path: "/resourceDownload",
      name: "resourceDownload",
      meta:{title:'资源下载'},
      component: () =>
        import( "@/views/resourceDownload/index.vue"),
    },

  ],
  // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
  // path 的值与 iframeRoutePath 相等
  iframes: []
};
