import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from 'element-ui'
import moment from 'moment'
import 'animate.css';
import 'normalize.css/normalize.css'// A modern alternative to CSS resets
import '@/filter/moment.js';
import '@/styles/element-variables.scss'
import '@/permission' // permission control
import '@/styles/index.scss' // global css
import axios from 'axios'
import VueAxios from 'vue-axios'
// table 鼠标拖拽滑动
import "@/utils/directive"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import hevueImgPreview from 'hevue-img-preview'
Vue.use(hevueImgPreview)
Vue.use(VueAwesomeSwiper)

Vue.use(VueAxios, axios)
// import '@/permission' // permission control
import '@/assets/iconfont/iconfont.css'
import '@/components/poster-editor/custom-component' // 注册海报组件
import { behavior } from '@/utils/behavior'



// 事件埋点
import track from './utils/track.js'
Vue.prototype.$track = track;


Vue.prototype.$behavior = behavior;
Vue.config.productionTip = false;

Vue.use(ElementUI, {
    size: 'small',
    zIndex: 2006
});


Vue.use(moment)
Vue.prototype.$Moment = moment;
Vue.config.devtools = true

// MockJs模拟数据移除
if (process.env.NODE_ENV !== 'production') {

}
//引用uniapp判断
import * as uni from './utils/uni.webview.1.5.2.js'  
document.addEventListener("UniAppJSBridgeReady", function() {  
    Vue.prototype.$uniapp = uni  
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
