
export default {
    routes: [
        {
            path: "/specialTest",
            name: "specialTest",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'专项测试'},
            component: () =>
              import(/* webpackChunkName: "specialTest" */ "@/views/specialTest"),
          },
          {
            path: "/specialTest/examination",
            name: "examination",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'专项测试'},
            component: () =>
              import(/* webpackChunkName: "specialTest" */ "@/views/specialTest/examination.vue"),
          },
          {
            path: "/specialTest/examinationCopy",
            name: "/examinationCopy",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'测试'},
            component: () =>
              import(/* webpackChunkName: "specialTest" */ "@/views/specialTest//examinationCopy.vue"),
          },
          {
            path: "/specialTest/testPaperDetail",
            name: "testPaperDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'专项测试',pvTitle:'测试'},
            component: () =>
              import(/* webpackChunkName: "specialTest" */ "@/views/specialTest/testPaperDetail.vue"),
          },
          {
            path: "/specialTest/myTest",
            name: "myTest",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'专项测试',pvTitle:'考试成绩查询'},
            component: () =>
              import(/* webpackChunkName: "specialTest" */ "@/views/specialTest/components/myTest.vue"),
          },
          {
            path: "/specialTest/testResult",
            name: "testResult",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'专项测试',pvTitle:'考试结果'},
            component: () =>
              import(/* webpackChunkName: "specialTest" */ "@/views/specialTest/components/testResult.vue"),
          },
          {
            path: "/specialTest/testCorrect",
            name: "testCorrect",
            meta:{title:'专项测试',pvTitle:'考试批改'},
            component: () =>
              import(/* webpackChunkName: "specialTest" */ "@/views/specialTest/components/testCorrect.vue"),
          },





    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
