import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)


const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})


// //海报编辑器
// const modulesFiles2 = require.context('./poster-editor', true, /\.js$/)
// const modules2 = modulesFiles2.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles2(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})

// const m = Object.assign({},modules,modules2);
import contextmenu from './poster-editor/contextmenu'
import copy from './poster-editor/copy'
import layer from './poster-editor/layer'
import snapshot from './poster-editor/snapshot'
import lock from './poster-editor/lock'
import compose from './poster-editor/compose'
import editor from './poster-editor/editor'
const store = new Vuex.Store({
  modules:modules,
  getters:getters,
  state: {
      ...compose.state,
      ...contextmenu.state,
      ...copy.state,
      ...layer.state,
      ...snapshot.state,
      ...lock.state,
      ...editor.state

  },
  mutations: {
      ...compose.mutations,
      ...contextmenu.mutations,
      ...copy.mutations,
      ...layer.mutations,
      ...snapshot.mutations,
      ...lock.mutations,
      ...editor.mutations


  },
})
export default store
