import Vue from 'vue'
import platform from 'platform'
import DateUtil from "./DateUtil"
import store from '../store'
import { eventLog,pvLog } from '@/api/bpl/event'
let params ={
    browser: platform.name,
    browerVersion:platform.version,
    client:'PC',
    browerVesolution: window.screen.width * window.devicePixelRatio +
    "*" +
    window.screen.height * window.devicePixelRatio,
    system:platform.family,
    systemVersion:platform.os.version,
} 

// 埋点指令
export const track = Vue.directive('track', {
    bind: (el, binding, vnode) => {
        el.addEventListener('click', () => {
            console.log(store.getters.trackData,'===========')
            params.module = store.getters.trackData.params.module
            params.page = store.getters.trackData.params.page
            params.resourceId = store.getters.trackData.params.resourceId
            const data = binding.value;
            var trackData = Object.assign({},params,data,{visitTime:DateUtil.getNowDateTime()})
            eventLog(trackData).then(response => {
                console.debug(response.data);
            });
 
        }, false);
    }
})
export const pvTrack = function(data){
  var trackData = Object.assign({},params,data,{visitTime:DateUtil.getNowDateTime()})
    pvLog(trackData).then(response => {
        console.debug(response.data);
    });
}
