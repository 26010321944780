
export default {
    routes: [
        {
            path: "/announcement",
            name: "announcement",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'公告'},
            component: () =>
              import(/* webpackChunkName: "announcement" */ "@/views/announcement"),
          },
          {
            path: "/announcement",
            name: "announcement",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'公告'},
            component: () =>
              import(/* webpackChunkName: "announcement" */ "@/views/announcement/index"),
          },
          {
            path: "/information",
            name: "information",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'资讯'},
            component: () =>
              import(/* webpackChunkName: "announcement" */ "@/views/announcement/information"),
          },
          {
            path: "/infoDetails",
            name: "infoDetails",
            meta:{title:'详情'},
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "announcement" */ "@/views/announcement/infoDetails.vue"),
          },
          
          {
            path: "/calendar",
            name: "calendar",
            meta:{title:'培训日历'},
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "announcement" */ "@/views/announcement/calendar.vue"),
          },
  
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
  