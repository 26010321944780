
export default {
    routes: [
     
    
  
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
  