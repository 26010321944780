import Cookies from "js-cookie";

export function setCookie(key,value,expires) {
  return Cookies.set(key, value,{ expires: expires})
}

export function getCookie(key) {
  return Cookies.get(key)
}


const TokenKey = 'loginToken'

export function getToken() {
//   return Cookies.get(TokenKey)
    return localStorage.getItem(TokenKey);

}

export function setToken(token) {
//   return Cookies.set(TokenKey, token)
    localStorage.setItem(TokenKey,token)
}

export function removeToken() {
    localStorage.removeItem(TokenKey);
//   return Cookies.remove(TokenKey)
}


const loginTime = 'loginTime'

export function getLoginTime() {
  return localStorage.getItem(loginTime);

}

export function setLoginTime(time) {
  localStorage.setItem(loginTime,time)
}

export function removeLoginTime() {
  localStorage.removeItem(loginTime);
}
