import Cookies from 'js-cookie'

const TokenKey = 'loginToken'

export function getToken() {
    return localStorage.getItem(TokenKey);

}

export function setToken(token,reToken) {
    localStorage.setItem(TokenKey,token)
    localStorage.setItem('refreshToken',reToken)
}

export function removeToken() {
    localStorage.removeItem(TokenKey);
    localStorage.removeItem('refreshToken')
}
