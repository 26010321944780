
export default {
    routes: [
        {
            path: "/liveList",
            name: "liveList",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'星享视界'},
            component: () =>
              import(/* webpackChunkName: "live" */ "@/views/live"),
          },
          {
            path: "/replayDetail",
            name: "replayDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'星享视界',pvTitle:'回播详情'},
            component: () =>
              import(/* webpackChunkName: "live" */ "@/views/live/replayDetail"),
          },
          {
            path: "/liveDetail",
            name: "liveDetail",
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            meta:{title:'星享视界',pvTitle:'直播详情'},
            component: () =>
              import(/* webpackChunkName: "live" */ "@/views/live/liveDetail"),
          },
          
  
    ],
    // 注意：通过 iframe 形式加载的路由页面，路由路径必须以 /iframe 开头，
    // path 的值与 iframeRoutePath 相等
    iframes: []
  };
  